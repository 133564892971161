 /* Default */

 body {
   padding: 0;
   margin: 0;
   color: #002d5b;
   font-family: "Open Sans", sans-serif;
   font-size: 15px;
 }

 a {
   outline: 0 !important;
   -webkit-transition: 0.5s;
   transition: 0.5s;
   color: #002d5b;
   text-decoration: none;
 }

 a:hover {
   color: #11cdd9;
   text-decoration: none;
 }

 p {
   color: #666666;
   margin-bottom: 12px;
   line-height: 1.8;
   font-size: 15px;
 }

 td {
   border: 0;
 }

 p:last-child {
   margin-bottom: 0;
 }

 button,
 input {
   outline: 0 !important;
 }

 .ptb-100 {
   padding-top: 100px;
   padding-bottom: 100px;
 }

 .pb-70 {
   padding-bottom: 70px;
 }

 .bg-blue-color {
   background-color: #002d5b;
 }

 .bg-f8f8f8 {
   background-color: #f8f8f8 !important;
 }

 .h1,
 .h2,
 .h3,
 .h4,
 .h5,
 .h6,
 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
   color: #002d5b;
   font-family: "Roboto", sans-serif;
 }

 .p-60 {
   padding: 60px;
 }
 .p-30 {
  padding: 30px;
}

 .bg-fafafa {
   background-color: #fafafa;
 }

 img {
   max-width: 100%;
 }

 .d-table {
   width: 100%;
   height: 100%;
 }

 .d-table-cell {
   vertical-align: middle;
 }

 /* Navbar */

 .header-area {
   width: 100%;
   height: auto;
   background-color: #002d5b;
 }

 .top-header {
   padding: 5px 0 15px;
 }

 .top-header .top-header-nav {
   padding-left: 0;
   margin-bottom: 0;
   list-style-type: none;
 }

 .top-header .top-header-nav li {
   display: inline-block;
   position: relative;
   margin-right: 12px;
   color: #ffffff;
   padding-left: 12px;
 }

 .top-header .top-header-nav li a {
   display: block;
   color: #ffffff;
 }

 .top-header .top-header-nav li a:hover {
   color: #11cdd9;
 }

 .top-header .top-header-nav li::before {
   content: '';
   position: absolute;
   left: 0;
   top: 9px;
   width: 6px;
   height: 6px;
   background-color: #11cdd9;
   border-radius: 50%;
 }

 .top-header .top-header-right-side {
   text-align: right;
 }

 .top-header ul {
   padding-left: 0;
   margin-bottom: 0;
   list-style-type: none;
 }

 .top-header ul li {
   width: 170px;
   display: inline-block;
   text-align: left;
   position: relative;
   padding-left: 52px;
   color: #ffffff;
   font-size: 12px;
   margin: 5px 20px 5px 0;
 }

 .top-header ul li .icon {
   width: 40px;
   height: 40px;
   line-height: 40px;
   border-radius: 50%;
   color: #002d5b;
   font-size: 20px;
   background-color: #11cdd9;
   text-align: center;
   position: absolute;
   left: 0;
   top: 50%;
   -webkit-transition: 0.5s;
   transition: 0.5s;
   -webkit-transform: translateY(-50%);
   transform: translateY(-50%);
 }

 .top-header ul li span {
   display: block;
 }

 .top-header ul li a {
   display: inline-block;
   color: #ffffff;
   font-size: 14px;
   font-family: "Roboto", sans-serif;
   font-weight: 500;
 }

 .top-header a.default-btn {
   background-color: #11cdd9;
   color: #002d5b;
   top: 4px;
   font-size: 13px;
   padding: 7px 15px;
 }

 .top-header a.default-btn span {
   background: #ffffff;
 }

 .top-header a.default-btn:hover {
   color: #002d5b;
 }

 .top-header ul li:hover .icon {
   background-color: #ffffff;
   color: #11cdd9;
 }

 .top-header .top-header-logo a {
   display: inline-block;
 }

 .navbar-area {
   -webkit-transition: 0.5s;
   transition: 0.5s;
 }

 .navbar-area.is-sticky {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   z-index: 999;
   -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
   box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
   background-color: #ffffff !important;
   -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
   animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
 }

 .navbar-area.is-sticky .pearo-nav .navbar {
   -webkit-box-shadow: unset;
   box-shadow: unset;
   padding-left: 0;
   padding-right: 0;
 }

 .pearo-responsive-nav {
   display: none;
 }

 @media only screen and (min-width: 992px) {
   .menu-icon.sticky-menubar {
     display: none;
   }

   .header-area {
     padding-bottom: 5px;
   }

   .drawer-menu {
     left: -300px !important;
   }

   .overlay {
     background-color: unset !important;
   }
 }

 @media only screen and (max-width: 991px) {
   .account-padding {
     padding: 0;
   }

   .account-main-menu {
     text-decoration: none;
     color: #002d5b;
     font-weight: 700;
     padding: 0;
   }

   .hide-on-md {
     display: none;
   }

   .pearo-responsive-nav {
     display: block;
   }

   .pearo-responsive-nav .pearo-responsive-menu {
     position: relative;
   }

   .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav {
     margin-top: 62px;
   }

   .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav ul {
     font-size: 15px;
   }

   .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav ul li a.active {
     color: #11cdd9;
   }

   .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav ul li li a {
     font-size: 14px;
   }

   .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option {
     position: absolute;
     right: 55px;
     top: 13px;
   }

   .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .option-item {
     color: #002d5b;
     display: inline-block;
     position: relative;
     line-height: 1;
   }

   .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .option-item .search-btn {
     cursor: pointer;
     -webkit-transition: 0.5s;
     transition: 0.5s;
     color: #002d5b;
     font-size: 20px;
     font-weight: 600;
   }

   .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .option-item .search-btn:hover {
     color: #11cdd9;
   }

   .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .option-item .close-btn {
     cursor: pointer;
     display: none;
     -webkit-transition: 0.5s;
     transition: 0.5s;
     width: 20px;
     color: #002d5b;
     font-size: 18px;
   }

   .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .option-item .close-btn.active {
     display: block;
   }

   .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .option-item .close-btn:hover {
     color: #11cdd9;
   }

   .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .burger-menu {
     display: none;
   }

   .pearo-responsive-nav .pearo-responsive-menu.mean-container .navbar-nav {
     overflow-y: scroll;
     height: 306px;
     -webkit-box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
     box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
   }

   .pearo-responsive-nav .mean-container a.meanmenu-reveal {
     color: #002d5b;
   }

   .pearo-responsive-nav .mean-container a.meanmenu-reveal span {
     background: #002d5b;
   }

   .pearo-responsive-nav .logo {
     position: relative;
     width: 50%;
     z-index: 999;
   }

   .navbar-area {
     background-color: #ffffff;
   }

   .pearo-nav {
     display: none;
   }

   .search-overlay.search-popup {
     width: 270px;
     right: -68px;
   }

   .search-overlay.search-popup .search-form .search-input {
     width: 94%;
   }

   .header-style-two .navbar-area {
     background-color: #002d5b;
   }

   .header-style-two .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .option-item {
     color: #ffffff;
   }

   .header-style-two .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .option-item .search-btn {
     color: #ffffff;
   }

   .header-style-two .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .option-item .search-btn:hover {
     color: #11cdd9;
   }

   .header-style-two .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .option-item .close-btn {
     color: #ffffff;
   }

   .header-style-two .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .option-item .close-btn:hover {
     color: #11cdd9;
   }

   .header-style-two .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .burger-menu {
     color: #ffffff;
   }

   .header-style-two .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .burger-menu:hover {
     color: #11cdd9;
   }

   .header-style-two .pearo-responsive-nav .mean-container a.meanmenu-reveal {
     color: #ffffff;
   }

   .header-style-two .pearo-responsive-nav .mean-container a.meanmenu-reveal span {
     background: #ffffff;
   }

   .header-style-four .navbar-area {
     background-color: #002d5b;
   }

   .header-style-four .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .option-item {
     color: #ffffff;
   }

   .header-style-four .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .option-item .search-btn {
     color: #ffffff;
   }

   .header-style-four .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .option-item .search-btn:hover {
     color: #11cdd9;
   }

   .header-style-four .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .option-item .close-btn {
     color: #ffffff;
   }

   .header-style-four .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .option-item .close-btn:hover {
     color: #11cdd9;
   }

   .header-style-four .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .burger-menu {
     color: #ffffff;
   }

   .header-style-four .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .burger-menu:hover {
     color: #11cdd9;
   }

   .header-style-four .pearo-responsive-nav .mean-container a.meanmenu-reveal {
     color: #ffffff;
   }

   .header-style-four .pearo-responsive-nav .mean-container a.meanmenu-reveal span {
     background: #ffffff;
   }
 }

 .pearo-nav {
   background-color: transparent;
 }

 .pearo-nav .navbar {
   background-color: #ffffff;
   -webkit-box-shadow: 7px -7px rgba(255, 255, 255, 0.6);
   box-shadow: 7px -7px rgba(255, 255, 255, 0.6);
   -webkit-transition: 0.5s;
   transition: 0.5s;
   padding-right: 25px;
   padding-top: 0;
   padding-left: 25px;
   padding-bottom: 0;
 }

 .pearo-nav .navbar .navbar-brand {
   padding-top: 0;
   padding-bottom: 0;
   padding-left: 0;
   padding-right: 0;
 }

 .pearo-nav .navbar ul {
   padding-left: 0;
   list-style-type: none;
   margin-bottom: 0;
 }

 .pearo-nav .navbar .navbar-nav {
   font-family: "Roboto", sans-serif;
   /* margin-left: auto; */
 }

 .pearo-nav .navbar .navbar-nav .nav-item {
   position: relative;
   padding: 0;
 }

 .pearo-nav .navbar .navbar-nav .nav-item a,
 .account-menu {
   font-size: 13.5px;
   font-weight: 700;
   color: #002d5b;
   text-transform: uppercase;
   padding-left: 0;
   padding-right: 0;
   /* padding-top: 30px;
    padding-bottom: 30px; */
   margin-left: 13px;
   margin-right: 13px;
 }

 .pearo-nav .navbar .navbar-nav .nav-item a:hover,
 .pearo-nav .navbar .navbar-nav .nav-item a:focus,
 .pearo-nav .navbar .navbar-nav .nav-item a.active,
 .account-menu:hover {
   color: #11cdd9;
 }

 .pearo-nav .navbar .navbar-nav .nav-item a i {
   font-size: 11px;
 }

 .pearo-nav .navbar .navbar-nav .nav-item:last-child a {
   margin-right: 0;
 }

 .pearo-nav .navbar .navbar-nav .nav-item:first-child a {
   margin-left: 0;
 }

 .pearo-nav .navbar .navbar-nav .nav-item:hover a,
 .pearo-nav .navbar .navbar-nav .nav-item.active a {
   color: #11cdd9;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu {
   -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
   box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
   background: #ffffff;
   position: absolute;
   border: none;
   top: 80px;
   left: 0;
   width: 250px;
   z-index: 99;
   display: block;
   opacity: 0;
   visibility: hidden;
   border-radius: 0;
   -webkit-transition: all 0.1s ease-in-out;
   transition: all 0.1s ease-in-out;
   margin-top: 20px;
   border-top: 2px solid #11cdd9;
   padding: 0;
   border-radius: 8px;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
   padding: 0;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
   text-transform: uppercase;
   padding: 10px 20px;
   margin: 0;
   position: relative;
   color: #002d5b;
   background-color: transparent;
   border-bottom: 1px dashed #e5e5e5;
   font-size: 11.5px;
   font-weight: 600;
   border-radius: 8px;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover,
 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus,
 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
   color: #ffffff;
   background-color: #11cdd9;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
   left: -250px;
   top: 15px;
   opacity: 0;
   visibility: hidden;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
   color: #002d5b;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover,
 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus,
 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
   color: #ffffff;
   background-color: #11cdd9;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
   left: 220px;
   top: 15px;
   opacity: 0;
   visibility: hidden;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
   color: #002d5b;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
   color: #ffffff;
   background-color: #11cdd9;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
   left: -250px;
   top: 15px;
   opacity: 0;
   visibility: hidden;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
   color: #002d5b;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
   color: #ffffff;
   background-color: #11cdd9;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
   left: -250px;
   top: 15px;
   opacity: 0;
   visibility: hidden;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
   color: #002d5b;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
   color: #ffffff;
   background-color: #11cdd9;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
   left: -250px;
   top: 15px;
   opacity: 0;
   visibility: hidden;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
   color: #002d5b;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
   color: #ffffff;
   background-color: #11cdd9;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
   left: -250px;
   top: 15px;
   opacity: 0;
   visibility: hidden;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
   color: #002d5b;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
   color: #ffffff;
   background-color: #11cdd9;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
   color: #ffffff;
   background-color: #11cdd9;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
   opacity: 1;
   visibility: visible;
   top: 0;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
   color: #ffffff;
   background-color: #11cdd9;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
   opacity: 1;
   visibility: visible;
   top: 0;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
   color: #ffffff;
   background-color: #11cdd9;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
   opacity: 1;
   visibility: visible;
   top: 0;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
   color: #ffffff;
   background-color: #11cdd9;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
   opacity: 1;
   visibility: visible;
   top: 0;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
   color: #ffffff;
   background-color: #11cdd9;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
   opacity: 1;
   visibility: visible;
   top: 0;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
   color: #ffffff;
   background-color: #11cdd9;
 }

 .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
   opacity: 1;
   visibility: visible;
   top: 0;
 }

 .pearo-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
   opacity: 1;
   visibility: visible;
   top: 100%;
   margin-top: 0;
 }

 .pearo-nav .navbar .others-option {
   margin-left: 25px;
 }

 .pearo-nav .navbar .others-option .option-item {
   color: #002d5b;
   display: inline-block;
   position: relative;
   line-height: 1;
 }

 .pearo-nav .navbar .others-option .option-item .search-btn {
   cursor: pointer;
   -webkit-transition: 0.5s;
   transition: 0.5s;
   color: #002d5b;
   font-size: 20px;
   font-weight: 600;
 }

 .pearo-nav .navbar .others-option .option-item .search-btn:hover {
   color: #11cdd9;
 }

 .pearo-nav .navbar .others-option .option-item .close-btn {
   cursor: pointer;
   display: none;
   -webkit-transition: 0.5s;
   transition: 0.5s;
   width: 20px;
   color: #002d5b;
   font-size: 18px;
 }

 .pearo-nav .navbar .others-option .option-item .close-btn.active {
   display: block;
 }

 .pearo-nav .navbar .others-option .option-item .close-btn:hover {
   color: #11cdd9;
 }

 .pearo-nav .navbar .others-option .burger-menu {
   margin-left: 15px;
   cursor: pointer;
   display: inline-block;
   font-size: 22px;
   color: #002d5b;
   -webkit-transition: 0.5s;
   transition: 0.5s;
 }

 .pearo-nav .navbar .others-option .burger-menu:hover {
   color: #11cdd9;
 }

 .drawer-menu {
   position: fixed;
   top: 0;
   left: -300px;
   /* Initially hidden */
   width: 300px;
   height: 100vh;
   background-color: #fff;
   transition: left 0.3s ease;
   z-index: 1001;
   overflow-y: scroll;
 }

 .drawer-menu ul {
   list-style: none;
   padding: 0;
   margin: 0;
 }

 .drawer-menu ul li {
   padding: 10px 20px;
   border-bottom: 1px solid #ddd;
 }

 .drawer-menu ul li:last-child {
   border-bottom: none;
 }

 .drawer-menu .main-menu {
   text-decoration: none;
   color: #002d5b;
   font-weight: 700;
 }

 .drawer-menu ul li a {
   text-decoration: none;
   color: #002d5b;
 }

 .drawer-menu.open {
   left: 0;
   /* Displayed when open */
 }


 .menu-icon {
   font-size: 24px;
   display: flex;
   align-items: center;
   cursor: pointer;
 }

 .container1 {
   position: relative;
 }

 .gradient-overlay1 {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   /* background: linear-gradient(to top, transparent, rgb(0, 29, 58)); Adjust the opacity and direction as needed */
 }

 /* footer */

 .footer-area {
   background-color: #002d5b;
   padding-top: 70px;
   position: relative;
   z-index: 1;
 }

 .footer-area::before {
   content: '';
   position: absolute;
   left: 0;
   top: 0;
   z-index: -1;
   width: 100%;
   height: 100%;
   /* background-image: url(../img/bg-line.png); */
   background-position: center center;
   background-size: cover;
   background-repeat: no-repeat;
 }

 /* subscribe */

 .subscribe-area {
   margin-bottom: 50px;
   padding-bottom: 50px;
   border-bottom: 1px solid #0c4776;
 }

 .subscribe-content {
   margin-right: 20px;
 }

 .subscribe-content h2 {
   margin-bottom: 0;
   color: #ffffff;
   text-transform: uppercase;
   font-size: 20px;
   font-weight: 700;
 }

 .subscribe-content p {
   color: #ffffff;
   opacity: 0.9;
   margin-top: 10px;
   margin-bottom: 0;
 }

 /* .subscribe-form {
    padding-left: 30px;
  } */

 .subscribe-form form {
   position: relative;
 }

 .subscribe-form form .input-newsletter {
   display: block;
   width: 100%;
   color: #002d5b;
   height: 50px;
   border-radius: 5px;
   border: none;
   padding-left: 10px;
   outline: 0;
   -webkit-box-shadow: unset !important;
   box-shadow: unset !important;
   font-size: 16px;
   font-weight: 500;
   font-family: "Open Sans", sans-serif;
 }

 .subscribe-form form .input-newsletter::-webkit-input-placeholder {
   color: #99afc3;
 }

 .subscribe-form form .input-newsletter:-ms-input-placeholder {
   color: #99afc3;
 }

 .subscribe-form form .input-newsletter::-ms-input-placeholder {
   color: #99afc3;
 }

 .subscribe-form form .input-newsletter::placeholder {
   color: #99afc3;
 }

 .subscribe-form form button {
   position: absolute;
   right: 5px;
   top: 5px;
   background-color: #11cdd9;
   color: #ffffff;
   border: none;
   border-radius: 5px;
   height: 40px;
   line-height: 40px;
   padding: 0 10px;
   -webkit-transition: 0.5s;
   transition: 0.5s;
   text-transform: uppercase;
   font-size: 15px;
   font-family: "Open Sans", sans-serif;
   font-weight: 700;
 }

 .subscribe-form form button:hover {
   color: #ffffff;
   background-color: #000000;
 }

 .subscribe-form #validator-newsletter {
   color: #ffffff;
   position: absolute;
   left: 0;
   bottom: -32px;
 }


 .single-footer-widget {
   margin-bottom: 30px;
 }

 .single-footer-widget h3 {
   margin-bottom: 30px;
   color: #ffffff;
   position: relative;
   padding-bottom: 8px;
   border-bottom: 1px solid #0c4776;
   text-transform: uppercase;
   font-size: 20px;
   font-weight: 900;
 }

 .single-footer-widget h3::before {
   bottom: -1px;
   left: 0;
   width: 55px;
   height: 1px;
   background-color: #11cdd9;
   content: '';
   position: absolute;
 }

 .single-footer-widget .logo a {
   display: inline-block;
 }

 .single-footer-widget .logo p {
   color: #ebe7e7;
   margin-bottom: 0;
   margin-top: 15px;
 }

 .single-footer-widget .social {
   padding-left: 0;
   list-style-type: none;
   margin-bottom: 0;
   margin-top: 20px;
 }

 .single-footer-widget .social li {
   display: inline-block;
   margin-right: 3px;
 }

 .single-footer-widget .social li a {
   display: inline-block;
   background-color: #11cdd9;
   color: #002d5b;
   width: 38px;
   font-size: 17px;
   height: 38px;
   line-height: 39px;
   border-radius: 50%;
   text-align: center;
 }

 .single-footer-widget .social li a i.flaticon-facebook {
   display: inline-block;
   padding-left: 4px;
 }

 .single-footer-widget .social li a i.flaticon-twitter {
   display: inline-block;
   position: relative;
   top: 2px;
 }

 .single-footer-widget .social li a:hover {
   background-color: #ffffff;
   color: #002d5b;
 }

 .single-footer-widget .social li:last-child {
   margin-right: 0;
 }

 .single-footer-widget .footer-quick-links {
   padding-left: 0;
   list-style-type: none;
   display: -ms-flexbox;
   display: -webkit-box;
   display: flex;
   -ms-flex-wrap: wrap;
   flex-wrap: wrap;
   margin-right: -15px;
   margin-left: -15px;
   margin-bottom: 0;
   margin-top: -12px;
 }

 .single-footer-widget .footer-quick-links li {
   -ms-flex: 0 0 100%;
   -webkit-box-flex: 0;
   flex: 0 0 100%;
   max-width: 100%;
   padding-left: 15px;
   padding-right: 15px;
   padding-top: 12px;
 }

 .single-footer-widget .footer-quick-links li a {
   display: inline-block;
   color: #ebe7e7;
   text-transform: uppercase;
   font-size: 14px;
 }

 .single-footer-widget .footer-quick-links li a:hover {
   color: #11cdd9;
 }



 .single-footer-widget .footer-contact-info {
   padding-left: 0;
   list-style-type: none;
   margin-bottom: 0;
 }

 .single-footer-widget .footer-contact-info li {
   color: #ebe7e7;
   font-size: 15px;
   margin-bottom: 12px;
 }

 .single-footer-widget .footer-contact-info li span {
   display: inline-block;
   font-weight: 600;
   color: #ffffff;
 }

 .single-footer-widget .footer-contact-info li a {
   display: inline-block;
   color: #ffffff;
   position: relative;
 }

 .single-footer-widget .footer-contact-info li a::before {
   width: 100%;
   height: 1px;
   background-color: #11cdd9;
   content: '';
   position: absolute;
   left: 0;
   bottom: 0;
   opacity: 0;
   visibility: hidden;
   -webkit-transition: 0.5s;
   transition: 0.5s;
 }

 .single-footer-widget .footer-contact-info li a:hover {
   color: #11cdd9;
 }

 .single-footer-widget .footer-contact-info li:last-child {
   margin-bottom: 0;
 }

 .single-footer-widget .footer-contact-info li:last-child a::before {
   opacity: 1;
   visibility: visible;
 }

 .copyright-area {
   margin-top: 40px;
   border-top: 1px solid #0c4776;
   padding-top: 25px;
   padding-bottom: 25px;
 }

 .copyright-area p {
   color: #ebe7e7;
   font-size: 15px;
 }

 .copyright-area p a {
   color: #ffffff;
   display: inline-block;
   font-weight: 600;
 }

 .copyright-area p a:hover {
   color: #11cdd9;
 }

 .copyright-area ul {
   padding-left: 0;
   margin-bottom: 0;
   list-style-type: none;
   text-align: right;
 }

 .copyright-area ul li {
   display: inline-block;
   color: #ebe7e7;
   font-size: 15px;
   position: relative;
   margin-left: 10px;
   margin-right: 10px;
 }

 .copyright-area ul li a {
   display: inline-block;
   color: #ebe7e7;
 }

 .copyright-area ul li a:hover {
   color: #11cdd9;
 }

 .copyright-area ul li::before {
   content: '';
   position: absolute;
   top: 5px;
   right: -13px;
   width: 1px;
   height: 14px;
   background-color: #ffffff;
 }

 .copyright-area ul li:last-child {
   margin-right: 0;
 }

 .copyright-area ul li:last-child::before {
   display: none;
 }

 .copyright-area ul li:first-child {
   margin-left: 0;
 }

 .quick-apply {
   position: fixed;
   right: 0px;
   top: 50%;
   -webkit-transform: translateY(-50%);
   transform: translateY(-50%);
   z-index: 5;
 }

 .quick-apply img {
   cursor: pointer;
   opacity: 1;
   visibility: visible;
   background-color: #fff;
   width: 35px;
   text-align: center;
   height: 35px;
   line-height: 35px;
   border: 1.5px solid #002d5b;
   border-radius: 7px;
   padding: 3px;
 }

 .quick-apply a span {
   /* position: absolute;
  top: 0;
  transition: right 0.3s ease; 
  display: none;
  width: 100%; */
   white-space: nowrap;
   width: auto;
   height: 32px;
   background-color: #fff;
   font-size: 12px;
   color: #002d5b;
   margin-top: 0;
   position: absolute;
   right: -175px;
   top: 2px;
   padding: 5px 10px;
   border-radius: 8px;
   z-index: -1;
   transition: transform 5s ease-out, opacity .5s;
 }

 .quick-apply a:hover span {
   display: block;
   right: 40px;
   /* transform: translateX(0); */
 }

 .go-top {
   position: fixed;
   cursor: pointer;
   top: 0;
   right: 15px;
   color: #ffffff;
   background-color: #002d5b;
   z-index: 4;
   width: 40px;
   text-align: center;
   height: 42px;
   line-height: 42px;
   opacity: 0;
   visibility: hidden;
   -webkit-transition: .9s;
   transition: .9s;
   border: 2px solid #11cdd9;
   border-radius: 7px;
 }

 .go-top.active {
   top: 98%;
   -webkit-transform: translateY(-98%);
   transform: translateY(-98%);
   opacity: 1;
   visibility: visible;
 }

 .go-top i {
   position: absolute;
   top: 50%;
   -webkit-transform: translateY(-50%);
   transform: translateY(-50%);
   left: 0;
   right: 0;
   margin: 0 auto;
   -webkit-transition: 0.5s;
   transition: 0.5s;
 }

 .go-top i:last-child {
   opacity: 0;
   visibility: hidden;
   top: 60%;
 }

 .go-top::before {
   content: '';
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: -1;
   background: #11cdd9;
   opacity: 0;
   visibility: hidden;
   -webkit-transition: 0.5s;
   transition: 0.5s;
 }

 .go-top:hover,
 .go-top:focus {
   color: #ffffff;
 }

 .go-top:hover::before,
 .go-top:focus::before {
   opacity: 1;
   visibility: visible;
 }

 .go-top:hover i:first-child,
 .go-top:focus i:first-child {
   opacity: 0;
   top: 0;
   visibility: hidden;
 }

 .go-top:hover i:last-child,
 .go-top:focus i:last-child {
   opacity: 1;
   visibility: visible;
   top: 50%;
 }

 .overlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.5);
   /* Semi-transparent black */
   z-index: 999;
   /* Ensure it appears above other content */
 }

 ::-webkit-scrollbar {
   width: 4px;
 }

 /* Track */
 ::-webkit-scrollbar-track {
   background: #f1f1f1;
 }

 /* Handle */
 ::-webkit-scrollbar-thumb {
   background: #888;
 }

 /* Handle on hover */
 ::-webkit-scrollbar-thumb:hover {
   background: #555;
 }

 .section {
   padding: 30px 0;
 }

 .section-60 {
   padding: 60px 0;
 }

 .section-title {
   text-align: center;
   /* max-width: 700px; */
   /* margin-bottom: 55px; */
   margin-left: auto;
   margin-right: auto;
   font-size: 20px;
   text-transform: uppercase;
   margin-bottom: 30px;
   font-weight: 900;
 }

 .section-title .sub-title {
   color: #11cdd9;
   display: block;
   margin-bottom: 10px;
   font-size: 18px;
 }

 .section-title h2 {
   margin-bottom: 0;
   text-transform: uppercase;
   font-size: 42px;
   font-weight: 900;
 }

 .section-title p {
   max-width: 610px;
   font-size: 16px;
   margin-left: auto;
   margin-top: 8px;
   margin-bottom: 0;
   margin-right: auto;
 }

 .single-services-box {
   position: relative;
   margin-bottom: 30px;
   text-align: center;
   z-index: 1;
   border-radius: 5px;
   background-color: #ffffff;
   padding: 30px;
   -webkit-transition: 0.5s;
   transition: 0.5s;
   -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
   box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
 }

 .single-services-box .icon {
   font-size: 65px;
   -webkit-transition: 0.5s;
   transition: 0.5s;
   position: relative;
   z-index: 1;
   margin-top: -15px;
   margin-bottom: 12px;
 }

 .single-services-box .icon .icon-bg img {
   position: absolute;
   left: 0;
   right: 0;
   margin: 0 auto;
   top: 50%;
   -webkit-transform: translateY(-50%);
   transform: translateY(-50%);
   width: auto !important;
   display: inline-block !important;
   z-index: -1;
 }

 .single-services-box h3 {
   margin-bottom: 18px;
   position: relative;
   padding-bottom: 15px;
   -webkit-transition: 0.5s;
   transition: 0.5s;
   text-transform: uppercase;
   font-size: 20px;
   font-weight: 900;
 }

 .single-services-box h3 a {
   display: inline-block;
 }

 .single-services-box h3::before {
   content: '';
   position: absolute;
   left: 0;
   bottom: 0;
   right: 0;
   margin: 0 auto;
   background-color: #e1dfe1;
   height: 1px;
   width: 70px;
   -webkit-transition: 0.5s;
   transition: 0.5s;
 }

 .single-services-box p {
   -webkit-transition: 0.5s;
   transition: 0.5s;
 }

 .single-services-box .read-more-btn {
   display: inline-block;
   margin-top: 10px;
   color: #002d5b;
   text-transform: uppercase;
   font-size: 15px;
   font-weight: 700;
 }

 .single-services-box .read-more-btn:hover {
   letter-spacing: .5px;
 }

 .single-services-box .box-shape img {
   position: absolute;
   left: 0;
   top: 0;
   width: auto !important;
   -webkit-transition: 0.5s;
   transition: 0.5s;
   display: inline-block !important;
 }

 .single-services-box .box-shape img:nth-child(2) {
   opacity: 0;
   visibility: hidden;
 }

 .single-services-box:hover {
   background-color: #002d5b;
 }

 .single-services-box:hover .icon {
   color: #11cdd9;
 }

 .single-services-box:hover h3 {
   color: #ffffff;
 }

 .single-services-box:hover h3 a {
   color: #ffffff;
 }

 .single-services-box:hover p {
   color: #ffffff;
 }

 .single-services-box:hover .read-more-btn {
   color: #11cdd9;
 }

 .single-services-box:hover .box-shape img:nth-child(1) {
   opacity: 0;
   visibility: hidden;
 }

 .single-services-box:hover .box-shape img:nth-child(2) {
   opacity: 1;
   visibility: visible;
 }

 .about-text a {
   font-weight: 700;
   color: #11cdd9 !important;
 }

 .about-text .read-more-btn i {
   -webkit-transition: 0.5s;
   transition: 0.5s;
   display: inline-block;
   font-weight: 700;
   color: #11cdd9;
 }

 .about-text .read-more-btn:hover i {
   margin-left: 3px;
 }

 .single-about-box,
 .loan-calc {
   height: 100%;
   background-color: #ffffff;
   padding: 30px;
   border-radius: 5px;
   text-align: center;
   -webkit-transition: 0.5s;
   transition: 0.5s;
   border: 2px solid #f8f8f8;
 }

 .single-about-box .icon {
   width: 100px;
   height: 100px;
   border-radius: 5px;
   background-color: #f8f8f8;
   color: #11cdd9;
   font-size: 50px;
   -webkit-transition: 0.5s;
   transition: 0.5s;
   margin-left: auto;
   margin-right: auto;
   margin-bottom: 25px;
 }

 .single-about-box h3 {
   margin-bottom: 10px;
   text-transform: uppercase;
   font-size: 15px;
   font-weight: 900;
 }

 .single-about-box h3 a {
   display: inline-block;
 }

 .single-about-box:hover {
   -webkit-transform: translateY(-10px);
   transform: translateY(-10px);
   -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
   box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
 }

 .about-image {
   position: relative;
   padding-bottom: 90px;
 }

 .about-image img:nth-child(2) {
   position: absolute;
   right: 0;
   bottom: 0;
   border-radius: 0 10px 0 0;
 }

 .loan-type i {
   font-size: 34px;
 }

 .loan-type p {
   font-size: 14px;
   line-height: 18px;
 }

 .loan-type > a:hover {
  color: #002d5b;
  text-decoration: none;
}

 .why-choose-us-area .container-fluid {
   padding: 0;
 }

 .why-choose-us-area .container-fluid .row {
   margin: 0;
 }

 .why-choose-us-area .container-fluid .row .col-lg-5,
 .why-choose-us-area .container-fluid .row .col-lg-7 {
   padding: 0;
 }

 .why-choose-us-content {
   background-color: #002d5b;
 }

 .why-choose-us-content .content {
   max-width: 705px;
   overflow: hidden;
   padding-left: 30px;
   /* padding-top: 50px; */
   padding-bottom: 50px;
 }

 .why-choose-us-content .content .features-list {
   padding-left: 0;
   list-style-type: none;
   /* display: -ms-flexbox;
   display: -webkit-box;
   display: flex; */
   /* -ms-flex-wrap: wrap;
   flex-wrap: wrap; */
   margin-left: -15px;
   margin-right: -15px;
   margin-bottom: 0;
   margin-top: 0;
 }

 .why-choose-us-content .content .features-list li {
   -ms-flex: 0 0 50%;
   /* -webkit-box-flex: 0; */
   /*   flex: 0 0 50%; */
   /* width: 50%; */
   color: #e5e5e5;
   position: relative;
   margin-bottom: 15px;
   padding-left: 65px;
   /* padding-right: 15px; */
   padding-top: 30px;
 }

 .why-choose-us-content .content .features-list li .icon {
   position: absolute;
   left: 0;
   /* top: 35px; */
   width: 50px;
   height: 50px;
   line-height: 50px;
   -webkit-transition: 0.5s;
   transition: 0.5s;
   border-radius: 50%;
   color: #ffffff;
   background-color: #11cdd9;
   text-align: center;
   font-size: 22px;
 }

 .why-choose-us-content .content .features-list li span {
   display: block;
   margin-bottom: 6px;
   color: #ffffff;
   text-transform: uppercase;
   font-size: 13px;
   font-weight: 700;
 }

 /* .why-choose-us-content .content .features-list li:hover .icon {
   background-color: #ffffff;
   color: #11cdd9;
 } */

 .single-feedback-item {
   position: relative;
   margin-left: 25px;
   margin-top: 25px;
   margin-bottom: 8px;
 }

 .single-feedback-item .feedback-desc {
   background-color: #002d5b;
   border-radius: 5px;
   position: relative;
   z-index: 1;
   padding: 30px 20px;
 }

 .single-feedback-item .feedback-desc p {
   margin-bottom: 0;
   position: relative;
   color: #ffffff;
   font-size: 14px;
   font-style: italic;
 }

 .single-feedback-item .feedback-desc p::before {
   content: '"';
   position: relative;
   display: inline-block;
   left: 0;
   color: #ffffff;
 }

 .single-feedback-item .feedback-desc p::after {
   content: '"';
   position: relative;
   display: inline-block;
   right: 0;
   color: #ffffff;
 }

 .single-feedback-item .feedback-desc::before {
   content: '';
   position: absolute;
   left: 35px;
   bottom: -12px;
   width: 25px;
   height: 25px;
   z-index: -1;
   background-color: #002d5b;
   -webkit-transform: rotate(45deg);
   transform: rotate(45deg);
 }

 .single-feedback-item::before {
   -webkit-transition: 0.5s;
   transition: 0.5s;
   /* content: "a"; */
   content: url('../img/Testimonials/common.jpg');
   position: absolute;
   left: 0;
   top: -25px;
   left: -25px;
   color: #ffffff;
   background-color: #3aade1;
   width: 60px;
   height: 60px;
   line-height: 78px;
   border-radius: 50%;
   z-index: 2;
   text-align: center;
   font-family: Flaticon;
   font-weight: normal;
   font-style: normal;
   font-size: 30px;
 }

 .single-feedback-item .client-info {
   position: relative;
   padding-left: 75px;
   margin-top: 35px;
   margin-left: 17px;
 }

 .single-feedback-item .client-info img {
   width: 60px !important;
   height: 60px !important;
   display: inline-block !important;
   border-radius: 50%;
   position: absolute;
   left: 0;
   top: 50%;
   -webkit-transform: translateY(-50%);
   transform: translateY(-50%);
   -webkit-transition: 0.5s;
   transition: 0.5s;
 }

 .single-feedback-item .client-info h3 {
   margin-bottom: 0;
   text-transform: uppercase;
   font-size: 18px;
   font-weight: 900;
 }

 .single-feedback-item .client-info span {
   display: block;
   color: #11cdd9;
   font-size: 14px;
   margin-top: 5px;
 }

 .banner-container {
   position: relative;
   /* display: inline-block; */
 }

 .banner-container img {
   display: block;
   height: auto;
   min-height: 400px;
   width: 100%;
   object-fit: cover;
 }

 .banner-text {
   position: absolute;
   top: 50%;
   -webkit-transform: translateY(-50%);
   transform: translateY(-50%);
   left: 20px;
   max-width: 50%;
 }

 .banner-text .default-btn {
   background-color: #11cdd9;
   color: #002d5b;
   padding: 7px 13px;
 }

 @media screen and (max-width: 500px) {
   .banner-text h3 {
     font-size: 20px;
   }

   .banner-text .default-btn {
     padding: 5px 8px;
     font-size: 12px
   }
 }

 .banner-gradient-overlay {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: linear-gradient(to right, rgb(0, 46, 91), rgba(255, 0, 0, 0));
 }

 .react-multi-carousel-dot-list {
   bottom: 2% !important;
 }

 .error-area {
   height: 100vh;
 }

 .error-content {
   text-align: center;
   margin: 0 auto;
   max-width: 700px;
 }

 .error-content h3 {
   text-transform: uppercase;
   font-size: clamp(30px, 4vw, 42px);
   font-weight: 900;
   margin-top: 45px;
   margin-bottom: 15px;
 }

 .error-content p {
   max-width: 520px;
   margin: 0 auto 25px;
 }

 .btn-default {
   background-color: #fff;
   color: #002d5b;
   border-radius: 8px;
   border: 1.5px solid #002d5b;
   padding: 8px 15px;
   font-weight: 500;
 }

 .btn-default:hover {
   background-color: #11cdd9;
   color: #002d5b;
 }

 .default-btn {
   border: none;
   position: relative;
   display: inline-block;
   text-align: center;
   overflow: hidden;
   z-index: 1;
   color: #ffffff;
   text-transform: uppercase;
   background-color: #002d5b;
   -webkit-transition: 0.5s;
   transition: 0.5s;
   border-radius: 5px;
   font-weight: 700;
   font-size: 15px;
   font-family: "Roboto", sans-serif;
   padding-left: 30px;
   padding-right: 30px;
   padding-top: 13px;
   padding-bottom: 13px;
 }

 .upload-btn {
   border: none;
   position: relative;
   display: inline-block;
   text-align: center;
   overflow: hidden;
   z-index: 1;
   color: #002d5b;
   text-transform: uppercase;
   background-color: #fff;
   -webkit-transition: 0.5s;
   transition: 0.5s;
   border-radius: 5px;
   border: 1px solid #002d5b;
   font-weight: 500;
   font-size: 13px;
   font-family: "Roboto", sans-serif;
   padding-left: 15px;
   padding-right: 15px;
   padding-top: 8px;
   padding-bottom: 8px;
 }

 button[disabled].default-btn {
   cursor: not-allowed !important;
 }

 button[disabled].default-btn:hover {
   cursor: not-allowed !important;
   color: #ffffff !important;
   background-color: #002d5b !important;
 }

 .default-btn span {
   position: absolute;
   display: block;
   width: 0;
   height: 0;
   border-radius: 50%;
   background-color: #11cdd9;
   -webkit-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
   transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
   -webkit-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);
   z-index: -1;
   border-radius: 5px;
 }

 .default-btn:hover,
 .default-btn:focus {
   color: #ffffff;
 }

 .default-btn:hover span,
 .default-btn:focus span {
   width: 225%;
   height: 562.5px;
 }


 .contact-form {
   max-width: 750px;
   margin-left: auto;
   margin-right: auto;
 }

 .contact-form form .form-control {
   background-color: #fff;
   border: none;
   -webkit-box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
   box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
   height: 50px;
 }

 .contact-form form .form-group {
   text-align: left;
 }

 .contact-form form .row {
   margin-left: -7px;
   margin-right: -7px;
 }

 .contact-form form .row .col-lg-12,
 .contact-form form .row .col-lg-6 {
   padding-left: 7px;
   padding-right: 7px;
 }

 .contact-form form textarea.form-control {
   height: auto;
   padding-top: 15px;
 }

 .contact-form form .default-btn {
   font-size: 12px;
   margin-top: 10px;
   -webkit-box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.5) !important;
   box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.5) !important;
 }

 .contact-form form .help-block ul {
   padding-left: 0;
   list-style-type: none;
   margin-top: 5px;
   margin-bottom: 0;
 }

 .contact-form form .help-block ul li {
   color: red;
 }

 .contact-form form #msgSubmit.text-danger,
 .contact-form form #msgSubmit.text-success {
   margin-top: 8px;
   font-size: 20px;
   font-weight: 600;
 }

 .accordion-item {
   border-bottom: 1px solid #eee;
 }

 .accordion-header {
   padding: 10px 20px;
   border-radius: 2px;
   font-size: 14px;
   font-weight: 700;
   cursor: pointer;
 }

 .accordion-header.active {
   background-color: #eee;
 }

 .accordion-content {
   padding: 30px;
 }

 .page-title-area {
   /* height: 500px; */
   position: relative;
   z-index: 1;
   background-position: center center;
   background-size: cover;
   background-repeat: no-repeat;
 }

 .page-title-bg1 {
   /* background-image: url(../img/ab.jpg); */
 }

 .page-title-bg2 {
   /* background-image: url(../img/page-title-image/2.jpg); */
 }

 .page-title-bg3 {
   background-image: url(../img/breadcrumbs.jpg);
   object-fit: cover;
 }

 .page-title-content {
   text-align: center;
   margin-top: 70px;
   margin-bottom: 70px;
 }

 .page-title-content h2 {
   margin-bottom: 0;
   color: #ffffff;
   text-transform: uppercase;
   font-size: 42px;
   font-weight: 900;
 }

 .page-title-content ul {
   padding-left: 0;
   list-style-type: none;
   margin-top: 12px;
   margin-bottom: 0;
 }

 .page-title-content ul li {
   color: #ffffff;
   display: inline-block;
   position: relative;
   font-weight: 400;
   font-size: 17px;
   margin-left: 10px;
   margin-right: 10px;
 }

 .page-title-content ul li a {
   display: inline-block;
   color: #ffffff;
 }

 .page-title-content ul li::before {
   content: '';
   position: absolute;
   right: -15px;
   top: 11px;
   width: 6px;
   height: 6px;
   border-radius: 50%;
   background-color: #11cdd9;
 }

 .page-title-content ul li:last-child::before {
   display: none;
 }

 .about-inner-area {
   margin-top: 30px;
 }

 .about-text-box {
   background-color: #ffffff;
   padding: 30px;
   margin-top: 30px;
   border-radius: 5px;
   -webkit-transition: 0.5s;
   transition: 0.5s;
   position: relative;
   z-index: 1;
 }

 .about-text-box h3 {
   margin-bottom: 17px;
   text-transform: uppercase;
   position: relative;
   border-bottom: 1px solid #eeeeee;
   padding-bottom: 12px;
   -webkit-transition: 0.5s;
   transition: 0.5s;
   font-size: 20px;
   font-weight: 900;
 }

 .about-text-box h3::before {
   content: '';
   position: absolute;
   left: 0;
   bottom: -1px;
   width: 50px;
   height: 1px;
   background-color: #11cdd9;
   -webkit-transition: 0.5s;
   transition: 0.5s;
 }

 .about-text-box h5 {
   margin-bottom: 10px;
   text-transform: uppercase;
   position: relative;
   border-bottom: 1px solid #eeeeee;
   padding-bottom: 8px;
   -webkit-transition: 0.5s;
   transition: 0.5s;
   font-size: 15px;
   font-weight: 900;
 }

 .about-text-box h5::before {
   content: '';
   position: absolute;
   left: 0;
   bottom: -1px;
   width: 50px;
   height: 1px;
   background-color: #11cdd9;
   -webkit-transition: 0.5s;
   transition: 0.5s;
 }

 .about-text-box p {
   -webkit-transition: 0.5s;
   transition: 0.5s;
   font-size: 14.5px;
 }

 .about-text-box::before {
   content: "";
   position: absolute;
   z-index: -1;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: #002d5b;
   -webkit-transform: scaleY(0);
   transform: scaleY(0);
   -webkit-transform-origin: 50% 0;
   transform-origin: 50% 0;
   transition-property: -webkit-transform;
   -webkit-transition-property: -webkit-transform;
   transition-property: transform;
   transition-property: transform, -webkit-transform;
   -webkit-transition-duration: 0.5s;
   transition-duration: 0.5s;
   -webkit-transition-timing-function: ease-out;
   transition-timing-function: ease-out;
   border-radius: 5px;
 }

 /* .about-text-box:hover {
   -webkit-transform: translateY(-10px);
   transform: translateY(-10px);
 }

 .about-text-box:hover h3 {
   color: #ffffff;
   border-color: #ffffff;
 }

 .about-text-box:hover h5 {
   color: #ffffff;
   border-color: #ffffff;
 }

 .about-text-box:hover p {
   color: #ffffff;
 }

 .about-text-box:hover::before {
   -webkit-transform: scaleY(1);
   transform: scaleY(1);
   
 } */

 .nav {
   flex-wrap: unset;
 }



 .nav-tabs,
 .stepper-slider {
   overflow-x: auto;
   white-space: nowrap;
   scrollbar-width: none;
   -ms-overflow-style: none;
 }

 .nav-tabs::-webkit-scrollbar,
 .stepper-slider::-webkit-scrollbar {
   display: none;
 }

 .nav-tabs .nav-link {
   background-color: #eee;
   border-radius: 12px;
   color: #002d5b;
   margin: 0 5px 30px 5px;
 }

 .nav-tabs .nav-link.active {
   background-color: #002d5b;
   border-radius: 12px;
   color: #fff;
   margin: 0 5px 30px 5px;
   /* box-shadow: inset #002d5b 0px 0px 30px -12px; */
   /* background-image: radial-gradient(transparent, #002d5b 98%); */
   /* border-bottom: unset;
   border-top: 1px solid #002d5b;
   border-right: 1px solid #002d5b;
   border-left: 1px solid #002d5b; */
 }

 .tab-content .fade:not(.show) {
   opacity: 1;
 }

 .mb-30 {
   margin-bottom: 30px !important;
 }

 .mt-30 {
   margin-top: 30px !important;
 }

 .mt-45 {
   margin-top: 45px !important;
 }

 #map {
   width: 100%;
   height: 100%;
 }

 iframe {
   width: 100%;
   height: 100%;
   border: none;
   /* Optional: Removes border around the iframe */
 }

 .header-btn {
   padding: 5px 15px;
   border: 0;
   border-radius: 25px;
   font-weight: 700;
   margin: 8px 0;
 }

 .my-sticky-header {
   background-color: #fff;
   box-shadow: 0 4px 2px -2px #002d5b;
   display: none;
   position: fixed;
   top: 0;
   z-index: 1000;
   width: 100%;
 }

 .my-sticky-header .navbar {
   box-shadow: unset !important;
 }

 @media only screen and (min-width: 768px) {

   .justify-content-banner {
     justify-content: left !important;
   }

   .partner-width {
     width: 12.5rem;
   }
 }

 .banner-img {
   width: 100%;
   max-width: 350px;
   height: auto;
   /* mix-blend-mode: multiply; */
 }

 .justify-content-banner {
   justify-content: center;
 }

 .rfm-marquee-container {
   overflow-x: hidden;
   display: flex;
   flex-direction: row;
   position: relative;
   width: 100%;
   transform: none;
 }

 .rfm-marquee-container:hover div {
   animation-play-state: paused;
 }

 .rfm-marquee-container:active div {
   animation-play-state: paused;
 }

 .rfm-marquee {
   flex: 0 0 auto;
   min-width: 100%;
   z-index: 1;
   display: flex;
   flex-direction: row;
   align-items: center;
   animation: scroll 44.8s linear 0s infinite;
   animation-play-state: running;
   animation-delay: 0s;
   animation-direction: normal;
 }

 @keyframes scroll {
   0% {
     transform: translateX(0%);
   }

   100% {
     transform: translateX(-100%);
   }
 }

 .rfm-initial-child-container {
   flex: 0 0 auto;
   display: flex;
   min-width: auto;
   flex-direction: row;
   align-items: center;
 }

 .rfm-child {
   transform: none;
 }

 .partner-width {
   width: 7rem !important;
 }

 .partner-image {
   transition-duration: .2s;
   transition-property: opacity;
   transition-timing-function: cubic-bezier(.4, 0, .2, 1);
   object-fit: contain;
   opacity: 1;
 }

 .step-form.completed,
 .step-form.active {
   background-color: #002d5b !important;
 }

 .loan-tab-header {
   justify-content: center;
   border-bottom: 0;
 }

 @media screen and (max-width:1000px) {
   .loan-tab-header {
     justify-content: left;
   }
 }

 .profile-menu {
   margin: 15px 0;
   padding: 10px 20px;
   border: 1px solid #002d5b;
   border-radius: 8px;
   list-style: none;
   background-color: #fff;
   /* font-weight: 700; */
   color: #002d5b;
   max-width: 250px;
 }

 .profile-menu:hover,
 .profile-menu.active {
   background-color: #002d5b;
   color: #fff;
   cursor: pointer;
 }

 .profile-input {
   background-color: #fff;
   padding: 30px 50px;
   border-radius: 8px;
 }

 .otp-container {
   display: flex;
 }

 .otp-input {
   width: 35px;
   height: 35px;
   text-align: center;
   margin-right: 10px;
   border: 1px solid #ced4da;
   border-radius: 0.25rem;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: #495057;
 }

 /* Optional: Styling for better visual appearance */
 .otp-input:focus {
   outline: none;
   border-color: #002d5b;
   /* Change this color to match your design */
 }

 .label-login {
   font-size: 14px;
   font-weight: 700;
   color: #002d5b;
   margin-bottom: 0px;
 }

 .resend-btn {
   background-color: transparent;
   border: 0;
   font-weight: 700;
   font-size: 14px;
   color: #002d5b
 }

 .eligibilityTable {
   background-color: #eee;
   border-radius: 8px;
   font-size: 14px;
 }

 .inside-border {
   border-collapse: collapse;
 }

 .inside-border td {
   border: 1px solid #fff;
   padding: 8px;
 }

 .inside-border tr:first-child td {
   border-top: none;
 }

 .inside-border tr:last-child td {
   border-bottom: none;
 }

 .inside-border td:first-child {
   border-left: none;
 }

 .inside-border td:last-child {
   border-right: none;
 }

 /* table .instantLoan, th, td {
  border: 1px solid black;
  border-collapse: collapse;
} */

.instant-company{
  display: flex;
  align-items: center;
  font-size: 26px;
  margin-left: 15px;
  font-weight: 700;
}

.instant-summary{
  background-color: #002d5b;
  border-radius: 16px;
  margin: 5px 40px;
  padding: 15px 10px 0 10px;
  color: #fff;
}

.single-card:hover {
  box-shadow: 1px 2px 5px 0px #002d5b
}

/* .fixed-background {
  background-image: url('../img/Loan/instant.png');
  background-size: 400px 300px;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 400px;
} */

.reminder {
  position: fixed;
  top: 20px;
  right: -470px; /* Hidden off-screen */
  width: 500px;  
  transition: right 0.3s ease-in-out;
  z-index: 1000;
  display: flex;
}

.reminder.visible {
  right: 20px; /* Slide in */
}

.reminder.hidden {
  right: -470px; /* Slide out */
  /* height: 50px; */
}

.reminder.visible > .reminder-content{
  margin-left: 0;
}

.reminder.hidden > .reminder-content{
  margin-left: 2px;
}

.reminder-content {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 15px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0,45,91, 0.3);
  border-radius: 5px;
  width: 100%;
}

.reminder-toggle {
  width: 30px;
  height: 30px;
  margin-top: 10px;
  border: 1px solid #002d5b;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0,45,91, 0.3);
  border-radius: 8px 0 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;  
  cursor: pointer;
}

.free-cibil{
  border-radius: 8px;
  border: 1px solid #11cdd9;
  padding: 15px;
  background-color: #cff5f7;
  width: 100%;
  cursor: pointer;
}

.loan-card{
  border-radius: 8px;
  border: 1px solid #eee;
  padding: 15px;
  width: 100%;
  cursor: pointer;
}

.loan-card a{
  text-decoration: none;
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
  .reminder {
    right: -320px; /* Hidden off-screen */
    width: 350px;
  }
  .reminder.hidden {
    right: -320px;
  }
}

@media only screen and (max-width: 575px) {
  .reminder {
    right: -220px; /* Hidden off-screen */
    width: 250px;
  }
  .reminder.hidden {
    right: -220px;
  }
}

.half-blue{
  margin: 0;
    height: 100vh;
    background: linear-gradient(to bottom, #002d5b 50%, white 50%);
  }

  .my-75{
    margin:75px 0;
  }
.banner-text-light{
  font-size: 12px;
}
.banner-desc{
  margin-top: 10px;
  font-size: 16px;
}
.banner-default-btn{
  margin-top: 15px;
  font-size: 12px;
  border: 0;
  padding: 5px;
  border-radius: 8px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-desc {
    font-size: 22px;
    margin-top: 15px;
  }
  .banner-default-btn{
    margin-top: 25px;
    font-size: 12px;
    padding: 7px 10px;
  }
}
@media only screen and (min-width: 992px) {
  .banner-desc {
    font-size: 28px;
    margin-top: 20px;
  }
  .banner-default-btn{
    margin-top: 40px;
    font-size: 14px;
    padding: 8px 12px;
  }
}

.modal-backdrop.show{
  opacity: 1;
  background: #fff;
}



/* div:where(.swal2-container).swal2-backdrop-show, div:where(.swal2-container).swal2-noanimation{
  background: #fff;
} */

.modal-border{
  box-shadow: 1px 2px 5px 0px #002d5b;
}

.react-html5-camera-photo>img, .react-html5-camera-photo>video{
  width: 100% !important;
}

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm){
  background-color: #002d5b;
}